import React, { useContext} from 'react'
import { genrateOperationContext } from '../../context/context'
import styled from 'styled-components';

export default function SubCategoryDropdown({subCategories,setOpenSubCategory,openSubCategory,open,setOpen}) {
  const value = useContext(genrateOperationContext);
  return (
    <Container>
      {subCategories.map((x,key)=>(
        <Item key={key} onClick={e=>{value.setGenrateOptions({...value.genrateOptions,subCategory:x}); setOpenSubCategory(!openSubCategory);setOpen(!open)}}>{x}</Item>
      ))}
    </Container>
  )
}
const Container = styled.div`
 z-index: 10;
 display: flex;
 justify-content: center;
 flex-direction: column;
 gap: 4px;
 background-color: lightblue;
 border-radius: 4px;
 
 `;
const Item = styled.div`
transition: 0.3s ease-in;
padding-left: 0.3rem;
 &:hover{
    background-color: #6bbbd6;
 }
`;