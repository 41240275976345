import React,{ useContext, useState,useEffect,useRef} from 'react'
import styled from 'styled-components'
import { genrateOperationContext } from '../../context/context';
import SubCategoryDropdown from './SubCategoryDropdown';

export default function DropdownButton({title,data,type,subCategory}) {
  
  const value = useContext(genrateOperationContext);
  const [open,setOpen] = useState(false);
  const [openSubCategory,setOpenSubCategory] = useState(false);
  const [item,setItem] = useState(title);
  const dropdownRef = useRef(null);
  const handleOpen = ()=>{
    setOpen(!open)
  }
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
        setOpenSubCategory(false);
    }
  };
 

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Dropdown ref={dropdownRef}>
     
      <DropdownSelection>
      <Dropdownbutton onClick={handleOpen}>{
           title==='Platform Name'?value.genrateOptions.platform.length===0?"Sources":value.genrateOptions.platform.map((x,key)=>(
            <>{key===0?<>{x}</>:<>{key<=2?<>{key<=1?<>, {x}</>:<>...</>}</>:<></>}</>}</>
           )):<>{item}{value.genrateOptions.subCategory && type === 'Category'?<>&nbsp;({value.genrateOptions.subCategory})</>:<></>}</>
        
        }</Dropdownbutton>
      {
        open===true?<svg onClick={handleOpen} style={{ transition:'0.2s ease-in',transform: 'rotate(180deg)', transformOrigin: 'center' }}  color='white' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
        </svg>:
      <svg onClick={handleOpen} style={{transition:'0.2s ease-in'}}  color='white' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
      </svg>
      }
      </DropdownSelection>


      {
      open===false?<></>:
       <DropdownContainer>
        {
           title==='Platform Name'?data.map((x,key)=>(<MDropdownContainer key={key}><input type="checkbox" onChange={(e)=>{
             var prev = value.genrateOptions.platform;
             if(e.target.checked)
              {
                prev.push(x); 
            }else{
              var i = 0;
              while (i < prev.length) 
                {
              if (prev[i] === x) {
               prev.splice(i, 1);
               } else {
                  ++i;
                    }
               }
            } 
            var st = new Set();
            prev.map((x,key)=>(
              st.add(x)
            )) 
            prev = [];
             for(const x of st)
             {
              prev.push(x);
             }

            value.setGenrateOptions({...value.genrateOptions,platform:prev})
           
           }} checked = {value.genrateOptions.platform.includes(x)} />{x}</MDropdownContainer>))
           :
             data.map((x,key)=>(
          <DropdownItem key={key} onClick={e=>{
            setItem(x);
            setOpen(true);
            setOpenSubCategory(!openSubCategory)
            if(title==='Select Category')
               {
                value.setGenrateOptions({...value.genrateOptions,category:x});
               }
         else{
                value.setGenrateOptions({...value.genrateOptions,numbers:x});
            }
          }}>
            {x} 
            {subCategory && type==='Category' && openSubCategory?<svg onClick={handleOpen} style={{ transition:'0.2s ease-in',transform: 'rotate(180deg)', transformOrigin: 'center' }}  color='white' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 15L17 10H7Z" fill="black" />
        </svg>:<>{ type ==='Category' && openSubCategory===false?<>
        <svg onClick={handleOpen} style={{transition:'0.2s ease-in'}}  color='white' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 15L17 10H7Z" fill="black" />
         </svg></>:
      <></>} </>}
          </DropdownItem>
        ))
      }
      {subCategory && type==='Category' && openSubCategory?<SubCategoryDropdown subCategories={subCategory} openSubCategory={openSubCategory} setOpenSubCategory={setOpenSubCategory} open={open} setOpen={setOpen}/>:<></>}
       </DropdownContainer>
      }
    
      </Dropdown>
  )
}
const Dropdown = styled.div`
 background-color: #fff;
  @media (max-width:768px)
 {
   min-height: 25vh;
 }
`;
const DropdownSelection = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 padding:1rem ;
 position: relative;
 svg{
  cursor: pointer;
  position: absolute;
   right: 12px;
 }
`;

const Dropdownbutton = styled.button`
 min-width: 12vw;
cursor: pointer;
  border: none;
  background-color: black;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  @media (max-width:768px)
 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 55vw;
}
`;
const DropdownContainer = styled.div`
background-color: white;
margin-left: 1rem;
z-index: 3;
position: absolute;
min-width: 12vw;
display: flex;
flex-direction: column;
 border-radius: 4px;
 cursor: pointer;
 box-shadow: 1px 1px 1px 4px gray;
 padding: 0.4rem 1rem;
 @media (max-width:768px) {
  position: relative;
  min-width: 15vw;
 }
`;
const MDropdownContainer = styled.div`
margin-top: 2%;
padding: 0.4rem 0.8rem;
 display: flex;
align-items: center;
gap: 5px;
 background-color:#fff;
 border-radius: 4px;
 cursor: pointer;

 &:hover{
  background-color: lightblue;
  border-radius: 10px;
 }
`;

const DropdownItem = styled.div`

transition: 0.3 ease-in;
text-align: center;
 display: flex;
 justify-content: space-between;
 padding-left: 0.2rem;
 &:hover{
  background-color: #7894e6;
  border-radius: 4px;
 }

`;