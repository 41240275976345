import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import DropdownButton from './DropdownButton';

export default function GenerateDropdown() {
    const [allData, setAllData] = useState([]);
    const [checking, setChecking] = useState(-1);
    const [setSelectedSubCategory] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/fetch-dropdowns`);
                const data = await response.json();
                setAllData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Main>
            {allData.map((x, key) => (
                <DropdownButton
                    key={key}
                    title={x.title}
                    data={x.options}
                    checking={checking}
                    setChecking={setChecking}
                    // subCategory={x.subCategory.map(subCat => subCat.name)}
                    subCategory={x.subCategory ? x.subCategory.map(subCat => subCat.name) : []}
                    type={x.type}
                    setSelectedSubCategory={setSelectedSubCategory}
                />
            ))}
        </Main>
    );
}

const Main = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;