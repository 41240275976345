import React, {useEffect, useState} from 'react'
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Generate from './pages/Generate';
import Home from './pages/Home';
import LandingPage from './pages/LandingPage';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import {userContext} from './context/context';
import FeedBack from './pages/FeedBack';
import Page404 from './pages/Page404';
import ContactUs from './pages/ContactUs';


export default function App() {


 const [user,setUser] = useState(''); 
 
 const handleUserFromToken = async()=>{
 var item = localStorage.getItem('token');
 if(!item) item = sessionStorage.getItem('token');

 try {
  const res = await fetch(`${process.env.REACT_APP_URL}/searchTokenAuth`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'  
      },
      body: JSON.stringify({
          token: item
      })
  });

  const responseData = await res.json();
 
  if (res.ok) {
      setUser({
        "userName":responseData.name, 
        "userImage":responseData.imageURL,
        "userEmail":responseData.email,
        "userAccessToken":responseData.token
      }) 

    console.log(res);
  } else {
      console.error('Error:', responseData);  
  }
} catch (error) {
  console.error('Fetch error:', error);  
}
 }


 useEffect(()=>{
   if(!user)
    {
  handleUserFromToken();
   }
 },[user,user.userName,user.userEmail])

  return (
          <userContext.Provider value={{user,setUser}}>
    <Router>
       <Routes>
        {user?<Route path='/generate' element={<Generate/>}/>:<></>}
         <Route path='/home' element={<Home/>}/>
         <Route path='/' element={<LandingPage/>}/>
         <Route path='/feedback' element={<FeedBack/>}/>
         <Route path='/login' element={<Login/>}/>
         <Route path='/signup' element={<SignUp/>}/>
         <Route path='/contactus' element={<ContactUs/>}/>
         <Route path='*'  element = {<Page404/>}/>
          
       </Routes>
    </Router>
         </userContext.Provider>
  )
}
