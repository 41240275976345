import React from 'react'
import styled from 'styled-components'
import blueicon1 from '../../utils/blueIcon1.PNG'
import blueicon2 from '../../utils/blueIcon2.PNG'
import blueicon3 from '../../utils/blueIcon3.PNG'
import blueicon4 from '../../utils/blueIcon4.PNG'
import blueicon5 from '../../utils/blueIcon5.PNG'
import blueicon6 from '../../utils/blueIcon6.PNG'


export default function HighLight() {
  return (
    <Contain>
         <Included>INCLUDED</Included>
         <h1>Manage your entire designs <br /> at single place</h1>
         
         <CardGrid>
             <Card>
                <img src={blueicon1} alt="icon" />
                <h2>Unique Designs</h2>
                <p>Get the design inspirations and <br /> generate unique designs </p>
             </Card>
             
             <Card>
                <img src={blueicon2} alt="icon" />
                <h2>Trend Analysis</h2>
                <p>Get help with real time trend analysis <br /> from the marketplaces </p>
             </Card>
             <Card>
                <img src={blueicon3} alt="icon" />
                <h2>Manage count</h2>
                <p>Increase or decrease the design count <br /> based on requirements  </p>
             </Card>
             <Card>
                <img src={blueicon4} alt="icon" />
                <h2>Designs print</h2>
                <p>We ensure that the generated <br /> inspirations are easily printed  </p>
             </Card>
             <Card>
                <img src={blueicon5} alt="icon" />
                <h2>Custom Mockups</h2>
                <p>Test the design on mockups and <br /> choose which looks good </p>
             </Card>
             <Card>
                <img src={blueicon6} alt="icon" />
                <h2>Easy Download</h2>
                <p>Easily download the images in required <br /> format like jpeg ,png ,svg etc </p>
             </Card>

         </CardGrid>

    </Contain>
  )
}

const Contain = styled.div`
 margin-top: 4%;
 min-height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
  flex-direction: column;

  h1{
     margin-top: 2%;
     line-height: 8.2vh;
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    color: #020244;
  }
  p{
     padding-top: 1.5rem;
    text-align: center;
    font-size: 18px;
    font-weight: 600;  
    color: #686666;
  }
`;
const Included = styled.div`
 background-color: #efeeee;
 padding: 0.2rem 0.7rem;
 font-weight: 600;
 font-size: 16px;
 border-radius: 15px;
`;
const CardGrid = styled.div`
 margin-top: 4%;
  margin-bottom: 2%;
min-width: 65vw;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-wrap: wrap;
 gap: 25px;
`;
const Card = styled.div`
min-width: 25vw;
min-height: 30vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
   
   h2{
    font-size: 20px;
    font-weight: 700;
   }
   p{
    font-size: 18px;
    font-weight: 500;
   }
   img{
    border-radius: 50%;
   }
`;
