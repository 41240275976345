import React from 'react';
// import styled from 'styled-components';

// const Box = styled.div`
// min-height: 100vh;
// display: flex;
// align-items: center;
// justify-content: center;
// `;
// const FeedbackContainer = styled.div`
//   background-color: #E9E9E9;
//   padding: 20px;
//   border-radius: 8px;
//   width: 500px;
//   margin: auto;
//   text-align: center;
// `;

// const Title = styled.h2`
//   margin-bottom: 20px;
//   font-size: 33px;
//   font-weight: 700;
// `;

// const Question = styled.p`
//   margin: 10px 0;
//   text-align: justify;
//   font-size: 18px;
//   font-weight: 600;
// `;

// const ButtonGroup = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 10px 0;
//   padding: 0.2rem 1rem;
//   gap: 5px;
// `;

// const Yes = styled.button`
//  font-size: 18px;
//  font-weight: 600;
//  min-height: 3vh;
//  min-width: 5vw;
//  border-radius: 4px; 
//  background-color: ${props => (props.active?'#ffc107':'#E9E9E9')};
//  &:hover{
//     background-color: #ffc107;
//  }
// `;
// const No = styled.button`
//  font-size: 18px;
//  font-weight: 600;
//  min-height: 3vh;
//  min-width: 5vw;
//  background-color: #000;
//  color: #fff;
//  border-radius: 4px;
//  transition: 0.3s ease-in;
//  background-color: ${props => (props.active?'#c51313':'#E9E9E9')};
// color: ${props => (props.active?'#fff':'#000')};
//  &:hover{
//     background-color: #c51313;
//  }
// `;

// const StarContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 5px;
//   margin: 10px 0;
// `;

// const Star = styled.span`
//   font-size: 32px;
//   cursor: pointer;
//   color: ${props => (props.active ? '#ffc107' : '#ccc')};

//   &:hover {
//     color: #ffc107;
//   }
// `;

// const TextArea = styled.textarea`
//   width: 100%;
//   height: 80px;
//   border-radius: 4px;
//   border: 1px solid #ccc;
//   padding: 10px;
//   margin-top: 10px;
//   outline: none;
//   &:focus {
//     border-color: #888;
//   }
// `;


// const Button = styled.button`
//  background-color: #09b0e3;
//  color: white;
//  padding: 0.2rem 1rem;
//  border-radius:4px ;
//  font-size: 18px;
//  font-weight: 600;
//  transition: 0.2s ease;
//  &:hover{
//   background-color: #1ec384;
//  }
// `;


const Feedback = () => {
  // const [problemSolved, setProblemSolved] = useState(null);
  // const [rating, setRating] = useState(0);
  // const [feedback, setFeedback] = useState('');

  // const handleRating = (index) => {
  //   setRating(index + 1);
  // };



  return (
<iframe title='feedback' src="https://docs.google.com/forms/d/e/1FAIpQLSejWrPeUbPPtgM592rPfJTS3iRLMDkSR9-wm6OhI3tiRPZo4w/viewform?embedded=true" width="640" height="988" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>  //  <Box>
  //    <FeedbackContainer>
  //     <Title>Feedback</Title>
  //     <Question>1. Does it solves your problem?</Question>
  //     <ButtonGroup>
  //      <Yes active={problemSolved === true} onClick={() => setProblemSolved(true)} >Yes</Yes>
  //      <No active={problemSolved === false} onClick={() => setProblemSolved(false)} >No</No>
  //     </ButtonGroup>
  //     <Question>2. How much will you rate this for your experience?</Question>
  //     <StarContainer>
  //       {[...Array(5)].map((star, index) => (
  //         <Star
  //           key={index}
  //           active={index < rating}
  //           onClick={() => handleRating(index)}
  //         >★</Star>
  //       ))}
  //     </StarContainer>
  //     <Question>3. Any feedback or suggestion to improve?</Question>
  //     <TextArea
  //       placeholder="Enter your feedback here"
  //       value={feedback}
  //       onChange={(e) => setFeedback(e.target.value)}
  //     />
       
  //      <Button>Submit</Button>
  //   </FeedbackContainer>
  //  </Box>
  );
};

export default Feedback;

