import React from 'react'
import styled from 'styled-components'
import sampleimage from '../../utils/sampleImage.jpeg';
import sampleimage2 from '../../utils/sampleImage2.avif'
import sampleimage3 from '../../utils/sampleImage3.avif'


export default function ProblemSolving() {
  return (
    <Contain>
                 <Heading>Current Problems we are solving</Heading>

                 <Case1>
                    <Data>
                      {/* <Feature>Feature</Feature> */}
                       <h1>Took long to get the right design <br /> inspirations?</h1>
                        <p> Take inspiration from  platforms like Pinterest and  generate unique <br /> design inspirations , along with the original reference links to reduce <br /> overall TAT and iteration cycles </p>
                    </Data>
                    <SampleImage src={sampleimage}/>
                 </Case1>

                  <Case2>
                     <img src={sampleimage2} alt="sampleimage3" />
                  <Data>
                      {/* <Feature>Feature</Feature> */}
                       <h1>Tough to stay updated with <br /> current trends ?</h1>
                        <p> We help to provide real time trend analysis and input parameters to <br /> build designs that works for your users</p>
                    </Data>
                </Case2>

                <Case3>
                  <Data>
                      {/* <Feature>Feature</Feature> */}
                       <h1>Will it be easy to use ?</h1>
                        <p>Yes our aim is to build what is simple to use and you are able to <br /> download designs in the required formats like SVG, PNG etc</p>
                    </Data>
                <img src={sampleimage3} alt="sampleImage3" />
                </Case3>

    </Contain>
  )
}

const Contain = styled.div`
          min-height: 100vh;
          display: flex;
          gap: 20px;
          align-items: center;
          justify-content: center;
          flex-direction: column;
`;
const Heading  = styled.div`
 margin-top: 45px;
 text-align: center;
 font-size: 28px;
 font-weight: 700;
`;
const Case1 = styled.div`

 padding-top: 8rem;
 display: flex;
 min-width: 70vw;
 min-height: 50vh;
 justify-content: space-between;
 @media (max-width:768px)
 {
  flex-direction: column;
 }
`;
const Data = styled.div`
 min-height: 40vh;
 min-width: 50vw;
 display: flex;
 flex-direction: column;
 gap: 8px;
  word-spacing: 3px;
  h1{
    font-weight: 700;
    font-size: 34px;
  }

p{
   color: gray;
 text-align: justify;
 font-weight: 600;
 font-size: 18px;
}
`;

const SampleImage = styled.img`
min-height: 15vh;
min-width: 17vw;
`;

const Case2 = styled.div`
 margin-top: 15%;
 display: flex;
 justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 80px;
  min-width: 70vw;
 min-height: 50vh;
`;

const Case3 = styled.div`
 margin-top: 12%;
 display: flex;
 justify-content: space-between;
 align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  min-width: 40vw;
  min-height: 50vh;
  img{
    min-height: 20vh;
    min-width: 30vw;
  }
`;