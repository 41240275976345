import React from 'react'
import styled from 'styled-components'
import HeroSection from '../components/landing/HeroSection';
import Navbar from '../components/Navbar';
import ProblemSolving from '../components/landing/ProblemSolving';
import HighLight from '../components/landing/HighLight';
import FAQ from '../components/landing/FAQ';
// import BenfitEarly from '../components/landing/BenfitEarly';



export default function LandingPage() {
 

  return (
    <Contain>
       <Navbar/>
       <HeroSection/>
       <ProblemSolving/>
        <HighLight/>
        <FAQ/>
        {/* <BenfitEarly/> */}
    </Contain>
  )
}

const Contain = styled.div`

`;
