import React from 'react'
import styled from 'styled-components';
import Navbar from '../components/Navbar'



export default function ContactUs() {
  return (
    <Contain>
        <Navbar/>
        <Main>
            <HeadButton>CONTACT</HeadButton>
            <h1>Contact Us</h1>
            <p>Have a question, suggestion, or just want to learn more about how it can solve <br />
            for you and we promise to get back within 48 hrs? Get in touch!</p>
            <FormSection>
                <Name placeholder='Name'/>
                <Email placeholder='Mobile No./Email'/>
                <Message placeholder='Message'/>
                <button>Send message</button>
            </FormSection>
        </Main>

    </Contain>
  )
}
const Contain = styled.div``;
const Main = styled.div`
min-height: 90vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

   h1{
    font-size: 54px;
    font-weight: 600;
   }
   p{
    text-align: center;
   }
`;
const HeadButton = styled.div`
margin-top: 2rem;
background-color: aliceblue;
padding: 0.3rem 1rem;
border-radius: 15px;
font-size: 14px;
font-weight: 700;
`;
const FormSection = styled.div`

margin-top: 3rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 8px;

button{
   margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  border:1px solid;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  transition: 0.3s ease;
  &:hover{
    color: white;
    background-color: black;
  }
}
`;
const Name = styled.input`
background-color: aliceblue;
min-width: 40vw;
min-height: 3rem;
padding: 0rem 2rem;
border-radius: 10px;
font: 18px;
`;
const Email = styled.input`
background-color: aliceblue;
min-width: 40vw;
min-height: 3rem;
padding: 0rem 2rem;
border-radius: 10px;
font: 18px;
`;
const Message = styled.input`
background-color: aliceblue;
min-width: 40vw;
min-height: 5rem;
padding-left: 2rem;
border-radius: 10px;
font: 18px;
`;

