import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom';
import {  signInWithEmailAndPassword } from 'firebase/auth';
import { userContext } from '../context/context';
import { auth,provider,signInWithPopup} from '../lib/firebaseConfig';
import StockAvtar from '../utils/StockAvtar.jpg'
import googlePNG from '../utils/googlePNG.png'

export default function Login() {
  
   const [remember,setRemember] = useState(false);
   const [email,setEmail] = useState("");
   const [password,setPassword] = useState("");
   const Navigate = useNavigate();

   const userValue = useContext(userContext);

    const handleRemember = (token)=>{
         if(remember)
         {          
                 localStorage.setItem("token",token);
         }else{
          sessionStorage.setItem("token",token)
         }
    }


   const onLogin = async (e) => {
      e.preventDefault();
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // Signed in
        const user = userCredential.user;
        userValue.setUser({
          "userName":user.displayName,
          "userEmail":user.email,
          "userAccessToken":user.accessToken,
          "userImage":user.photoURL?user.photoURL:StockAvtar 
        })
        
        try {
          const res = await fetch(`${process.env.REACT_APP_URL}/loginAuth`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'  // Specify the content type as JSON
              },
              body: JSON.stringify({
                  email: user.email,
                  token: user.accessToken
              })
          });
          const responseData = await res.json();
          if (res.ok) {
              console.log('Success:', responseData);  
          } else {
              console.error('Error:', responseData);  
          }
      } catch (error) {
          console.error('Fetch error:', error);  
      }
    

        handleRemember(user.accessToken);
        Navigate("/genrate");
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        Navigate('/')
      }
    };



    const GoogleSignIn = async () => {
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
            userValue.setUser({
          "userName":user.displayName,
          "userEmail":user.email,
          "userAccessToken":user.accessToken,
          "userImage":user.photoURL?user.photoURL:StockAvtar 
        })
         
        try {
          const res = await fetch(`${process.env.REACT_APP_URL}/signInWithGoogleAuth`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json'  // Specify the content type as JSON
              },
              body: JSON.stringify({
                email: user.email,
                name:user.displayName,
                imageURL:user.photoURL?user.photoURL:StockAvtar,
                token: user.accessToken
              })
          });
          const responseData = await res.json();
          if (res.ok) {
              console.log('Success:', responseData);  
          } else {
              console.error('Error:', responseData);  
          }
      } catch (error) {
          console.error('Fetch error:', error);  
      }
        localStorage.setItem("token",user.accessToken);
        Navigate('/genrate');
      } catch (error) {
        console.error('Error during sign-in:', error);
        Navigate('/')
      }
    };



 
    

  return (
    <Contain>
                 <Heading>Log In to your Account</Heading>
                 <form action="">
                  <Email>
                     <p>Email</p>
                    <input type="text" placeholder='johnsondoe@nomail.com' value={email} onChange={e=>setEmail(e.target.value)}/>
                  </Email>
                  <Password>
                   <p> Password</p>
                   <input type="password" placeholder='***************' value={password} onChange={e=>setPassword(e.target.value)}/>
                  </Password>
                  <Remember>
                    <input type="checkbox" onChange={e=>{setRemember(e.target.checked)}}/>
                    <p>Remember me</p>
                  </Remember>
                  <Continue type='submit' onClick={onLogin}>CONTINUE</Continue>
                </form>

                   <h5>Or</h5>
                   <GoogleSection  onClick={GoogleSignIn}>
                <img src={googlePNG} alt="googleimage" />
                   <GoogleLogin>
                        Login with Google
                   </GoogleLogin>
              </GoogleSection>
                   <NewUser>
                        <p>New User? </p>
                        <Link style={{fontWeight:'700'}} to={'/signup'}>Sign Up</Link>
                   </NewUser>
    </Contain>
  )
}

const Contain = styled.div`
 min-height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;


 form{
    display: flex;
 align-items: center;
 flex-direction: column;
 gap: 15px;
 }

  h5{
    margin-top: 3%;
    margin-bottom: 2%;
    font-weight: 700;
    font-size: 18px;
  }
`;
const Heading = styled.h1`
 text-align: center;
 font-size: 24px;
 font-weight: 600;
 margin-bottom: 4%;
`;
const Email = styled.div`
 position: relative;

    
 p{
    padding: 0.3rem;
 z-index: 3;
 position: absolute;
 left: 15px;
 top: -16px;
 background-color: #fff;
 }
 input{
    border: 0.3px solid black;
    padding: 0.8rem 1rem;
    min-width: 25vw;
    border-radius: 8px;
 }

`;
const Password = styled.div`
color: gray;
position: relative;
 p{
    padding:0px 2px 0px 2px;
 z-index: 3;
 position: absolute;
 left: 15px;
 top: -14px;
 background-color: #fff;
 }
 input{
    border: 0.3px solid gray;
    padding: 0.8rem 1rem;
    min-width: 25vw;
    border-radius: 8px;
 }

`;
const Remember = styled.div`
min-width: 25vw;
display: flex;
align-items: center;
gap: 5px;
input{
    cursor: pointer;
}
`;
const Continue = styled.button`
 background-color: #000;
 color: #fff;
 text-align: center;
 min-width: 25vw;
 padding: 0.8rem 1rem;
 border-radius: 8px;
 font-weight: 500;
`;

const GoogleSection = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: row;
 gap: 2rem;
 cursor: pointer;
 min-width:25vw;
 height: 7vh;
 padding-top: 1rem ;
 padding-bottom: 1rem;
 border: 0.2px solid gray;
 border-radius: 12px;
 margin-bottom: 2rem;

 img{
  height: 3vh;
 }
`;

const GoogleLogin = styled.button`

 border-radius: 8px;
 font-weight: 500;
 font-size: 18px;
`;
const NewUser = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 4px;
`;
