import React,{useState} from 'react'
import styled from 'styled-components'
import DropdownButton from './DropdownButton';

export default function GenrateDropdown() {
    const allData = [
      {
         "type":"Category",
        "title":"Select Category",
        "options":[
           'Anime'
        ],
        "subCategory":["DragonBall","Naruto","Demon Slayer","Chainsaw Man","Deathnote","Attack on Titan", "HunterXHunter","My Hero Academia","Jujutsu Kaisen", "One Piece"]
      },
      {
        "type":"Platform",
        "title":"Platform Name",
        "options":[
          'Pinterest',
          'Vecteazy'
        ]
      },
      { 
        "type":"ImageNumber",
        "title":"No. of images",
        "options":[
          "1", "2", "3", "5", " 10"
        ]
      }
    ]
     const [checking,setChecking] = useState(-1);
  return (
     <Main>  
          {
            allData.map((x,key)=>(
              <DropdownButton key={key} title={x.title} data={x.options} checking={checking} setChecking={setChecking} subCategory = {x.subCategory} type = {x.type}/>   
            ))
          }   
     </Main>
  )
}

const Main = styled.div`
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 gap: 12px;

 @media (max-width:768px)
 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
`;

