
import React,{useRef} from 'react'
import styled from 'styled-components'
import fullscreen from '../../utils/fullscreen.svg'




export default function FullScreenComp({url}) {
    


    const imageRef = useRef(null);
  const enterFullScreen = () => {
    if (imageRef.current.requestFullscreen) {
      imageRef.current.requestFullscreen();
    } else if (imageRef.current.webkitRequestFullscreen) { /* Safari */
      imageRef.current.webkitRequestFullscreen();
    } else if (imageRef.current.msRequestFullscreen) { /* IE11 */
      imageRef.current.msRequestFullscreen();
    }
  };

  return (
    <ImageFramer>
    <img ref={imageRef} src={url} alt="results images" />
     <ActionButtons>
     <FullScreen onClick={enterFullScreen}><img src={fullscreen} alt="" /></FullScreen>
     </ActionButtons>
   </ImageFramer>
  )
}


const ImageFramer = styled.div`
cursor: pointer;
position: relative;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

border-radius: 100%;


img{
 width: 2.1vw;
 height: 30px;
 opacity: 90%;
 }
@media (max-width:768px) {
  width: 2vw;
  }
  
`;
const ActionButtons = styled.div`
position: absolute;
 display: flex;
 justify-content: center;
 align-items: center;
 opacity: 100%;
 transition: 0.15s ease-out;
  &:hover{
    background-color: lightgray;
    opacity: 100%;
    border-radius: 100%;
  }

 
`;

const FullScreen = styled.div`
 background-color: lightgray;
 width: 100%;
  border-radius: 50%;
  transition: 0.3s ease;
  &:hover{background-color:aliceblue}
`;