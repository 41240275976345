import React, {  useState,useRef, useEffect } from 'react'
import Navbar from '../components/Navbar'
import styled from 'styled-components'
import GenrateDropdown from '../components/genrate/GenrateDropdown';
import { genrateOperationContext } from '../context/context';
import MockUpCard from '../components/genrate/MockUpCard';
// import fullscreen from '../utils/fullscreen.svg'
import loading from '../utils/loading.gif'
import FullScreenComp from '../components/genrate/FullScreenComp';



export default function Genrate() {

  const [mockUpDailougeBox,setMockUpDailougeBox] = useState(false);
  const [currOpenKey,setCurrOpenKey] = useState(-1);
  const [imageMetaData,setImageMetaData] = useState();
  const [reqMetaData,setReqMetaData] = useState();
  const [mockUpData,setMockUpData] = useState();
  // const [resultForGenerate,setResultForGenerate] = useState();
  const url1 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtSdMFwHf_YkpMfc-UwyeByhsLhqOwWJ0SLQ&s';
  const url3 = 'https://i.pinimg.com/736x/2d/3a/1d/2d3a1dc45a181bc9772756b7ffe5a2d8.jpg';
  const url4 = 'https://i.pinimg.com/736x/62/62/49/626249b84409e2d4e9168188897c3608.jpg';
 
  const [dummy,setDummy] = useState([{
    "id":0,
    "url":url1
  },
  {
    "id":1,
    "url":url3
  },
  {
    "id":2,
    "url":url4
  },
])

  const [genrateOptions,setGenrateOptions] = useState({
    "category":"",
    "platform":[],
    "numbers":"",
    "inspiration":"",
    "subCategory":""
  }); 
 console.log("Option Selected for Generation =>",genrateOptions);
 
 
 
  const imageRef = useRef(null);
  // const enterFullScreen = (imageSRC) => {
          
  //   imageRef.current.src = imageSRC

  //   if (imageRef.current.requestFullscreen) {
  //     imageRef.current.requestFullscreen();
  //   } else if (imageRef.current.webkitRequestFullscreen) { /* Safari */
  //     imageRef.current.webkitRequestFullscreen();
  //   } else if (imageRef.current.msRequestFullscreen) { /* IE11 */
  //     imageRef.current.msRequestFullscreen();
  //   }
  // }; 

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      
        setMockUpDailougeBox(false);
        setCurrOpenKey(-1)
    }
  };

 
  const handleGenerate = async () => {
    setDummy([{
      "id":0,
      "url":loading
    }])
    try {
      // Transform the platform names to lowercase
      const mp = genrateOptions.platform.map((x) => x.toLocaleLowerCase());
  
      // Make the API request
      const res = await fetch(`${process.env.REACT_APP_URL}/generate-by-category`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subCategory: genrateOptions.subCategory?genrateOptions.subCategory:"DragonBall",
          category: genrateOptions.category?genrateOptions.category.toLocaleLowerCase():"Anime",
          numberOfImages: genrateOptions.numbers?parseInt(genrateOptions.numbers,10):1,
          source: genrateOptions.platform?mp:['pinterest'],
          prompt: genrateOptions.inspiration?genrateOptions.inspiration:"Generate any character from (dbSuperStyle)Anime",
        }),
      });
      // Parse the response
      const data = await res.json();
      setImageMetaData(data.images);
      // Process each image's Base64 data
      if (data && data.images && Array.isArray(data.images)) {
        const imageUrls = data.images.map((base64String, index) => {
          try {
            // Decode the base64 string into an image source URL
            return decodeBase64ToImageSrc(base64String);
          } catch (decodeError) {
            console.error(`Error decoding image ${index + 1}:`, decodeError);
            return null;
          }
        }).filter(Boolean);
  
        // Use the imageUrls array to render the images
        imageUrls.forEach((imageUrl, index) => {
          // console.log(`Image ${index + 1}:`, imageUrl);
          setDummy(oldDummy =>[...oldDummy,{
            "id":index+1,
            "url":imageUrl
          }])
          // Render the image using the imageUrl
          // For example:
          // <img src={imageUrl} alt={`Image ${index + 1}`} />
        });
      }
    } catch (error) {
      // Handle errors during the fetch request
      console.log("An error occurred:", error);
    }
  };
  
  // Helper function to convert Base64 to image source URL
  const decodeBase64ToImageSrc = (base64String, mimeType = 'image/png') => {
    return `data:${mimeType};base64,${base64String}`;
  };


 //for mockup image extraction from backend
 const handleMockUpLink = async (idAndUrl)=>{
  console.log("data for conversion in mockups => ", dummy);
  console.log("meta data for conversion in mockups => ", imageMetaData);

  
   
   if(idAndUrl)
  {
     
    if(imageMetaData) {
      //taking id - 1 because in dummy data there is an extra image of gif exist which we cannot map after result generation
      // so while including for metadeta we simple take idx - 1
      setReqMetaData(imageMetaData[idAndUrl.id-1]);
    }
    else{
       setReqMetaData(dummy[idAndUrl.id].url);
    }
    
    // console.log("data set for sending => ", reqMetaData);
    
          
    const res = await fetch(`${process.env.REACT_APP_URL}/createTshirtMockup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'metaData':reqMetaData
      }),
    });

    const resData = await res.json();

    console.log("response Data => ",resData.merged_image);
    setMockUpData(resData.merged_image)
    
  }
  
   
 }
 

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
   

  return (
    <genrateOperationContext.Provider value ={{genrateOptions,setGenrateOptions}}>
   <Contain>
      <Navbar/>
      <Main>
        <UpperInput>
           <h1>Apparel Desigining made Simple !</h1>
           <input type="text" placeholder='✨  what inspiration do you want to generate?' onChange={e=>{setGenrateOptions({...genrateOptions,inspiration:e.target.value});}}/>
          </UpperInput> 

          {/* option selection button  */}
           <OptionDropDown>
              <GenrateDropdown/>
           </OptionDropDown>

           <GenerateButton onClick={handleGenerate}>Generate Now</GenerateButton>

           <ResultSection>
               <OutputSection>
                       { 
                           dummy.map((x,key)=>(
                           key>=1 || dummy.length===1?<ImageFramer  key={key}>
                                    <img ref={imageRef} src={x.url} alt="results images" />
                                     <ActionButtons>

                                        {/* like button */}
                                     <SVGlike  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M2 21H6V9H2V21ZM23 10.9999C23 9.89995 22.1 8.99995 21 8.99995H14.72L15.64 4.63995L15.66 4.33995C15.66 3.74995 15.39 3.19995 14.96 2.84995L14.28 2.27995L7.6 8.53995C7.22 8.87995 7 9.40995 7 9.99995V18.9999C7 20.1 7.9 20.9999 9 20.9999H18C18.71 20.9999 19.31 20.6299 19.66 20.0699L22.86 14.4799C22.95 14.3299 23 14.1599 23 13.9999V10.9999Z" fill="black"/>
                                      </SVGlike>
                                       
                                        {/* Dislike button */}
                                      <SVGdislike width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M22 3H18V15H22V3ZM2 14.9999C2 16.0999 2.9 16.9999 4 16.9999H10.28L9.36 21.3599L9.34 21.6599C9.34 22.2499 9.61 22.7999 10.04 23.1499L10.72 23.7199L17.4 17.4599C17.78 17.1199 18 16.5899 18 15.9999V6.99995C18 5.89995 17.1 4.99995 16 4.99995H7C6.29 4.99995 5.69 5.36995 5.34 5.92995L2.14 11.5199C2.05 11.6699 2 11.8399 2 11.9999V14.9999Z" fill="black"/>
                                        </SVGdislike>
                                        
                                        {/* Downloading button */}
                                        <SVGdownload width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M5 20H19V18H5V20ZM12 3L12 14M12 14L8 10M12 14L16 10" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                          </SVGdownload>
                                          
                                           <button onClick={e=>{
                                            if(key!==currOpenKey && currOpenKey!==-1){

                                            }else if(mockUpDailougeBox===false)
                                              {
                                                setMockUpDailougeBox(true);
                                                   setCurrOpenKey(key);
                                              }else{
                                                setCurrOpenKey(-1);
                                                setMockUpDailougeBox(false);
                                              }
                                            handleMockUpLink(x)
                                           }}>Mockups</button>


                                        {/* <FullScreen onClick={e=>{enterFullScreen(x.url)}}><img src={fullscreen} alt=""/></FullScreen> */}
                                        <FullScreen >

                                        <FullScreenComp url={x.url}/>

                                        </FullScreen>



                                     </ActionButtons>
                                     {
                                                mockUpDailougeBox===true && currOpenKey===key?
                                              <MockUpBox ref={dropdownRef} key={key}>
                                                   <svg onClick={e=>{
                                            if(key!==currOpenKey && currOpenKey!==-1){

                                            }else if(mockUpDailougeBox===false)
                                              {
                                                setMockUpDailougeBox(true);
                                                   setCurrOpenKey(key);
                                              }else{
                                                setCurrOpenKey(-1);
                                                setMockUpDailougeBox(false);
                                              }
                                           }} style={{transform:'rotate(45deg)'}} width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><line x1="10" y1="50" x2="90" y2="50" stroke="black" strokeWidth="10"/><line x1="50" y1="10" x2="50" y2="90" stroke="black" strokeWidth="10"/></svg>
                                                    <h3>Test Mockup</h3>
                                                  <CardSection>
                                                     {
                                                        mockUpData?.map((mockUpInfo,key)=>(
                                                          <MockUpCard key={key} url={decodeBase64ToImageSrc(mockUpInfo.metaData)?decodeBase64ToImageSrc(mockUpInfo.metaData):x.url}/>
                                                        ))
                                                     }
                                         
                                                  </CardSection>
                                                </MockUpBox>:<></>
                                              }       
                            </ImageFramer>:<></>
  
                           ))
                          //  :<></>
                       }            
                                 
               </OutputSection>
           </ResultSection>
        
      </Main>
    </Contain>
    </genrateOperationContext.Provider>
  )
}

// styling part is present here
const Contain =  styled.div`
 display: flex;
 flex-direction: column;
`;
const Main = styled.div`
 display: flex;
 flex-direction: column;
 justify-content:center;
 align-items: center;
 min-height: 50vh;
`;
const UpperInput = styled.div`
   margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
   h1{
     font-weight: 700;
     font-size: 44px;
     text-align: center;
    background: #5F5CE8;
    background: linear-gradient(to right, #5F5CE8 0%, #221A1A 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
   }
   input{
    color: black;
    font-weight: 600;
    min-width: 50vw;
    min-height: 6vh;
    border: 1px solid;
    padding: 0.3rem 1rem;
    margin-top:45px;
    border-radius: 28px;
    @media (max-width:768px)
    {
    min-width:95vw;
    margin-bottom: 3%;
    }
   }
`;

const OptionDropDown = styled.div`

z-index: 3;
margin-top: 3%;
min-height: 25vh;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
gap: 25px;
`;

const GenerateButton = styled.button`

cursor: pointer;
border: none;
border: 1px solid black;
background-color: #3ebfee;
font-weight: 550;
min-width:10rem;
min-height: 3rem;
padding: 0.3rem 1rem;
font-size: 18px;
border-radius: 5px;
transition: 0.3s ease;
 &:hover{
  background-color: #2f46b7;
  color: white;
  border: none;
 }
`;


const ResultSection = styled.div`
 margin-top: 5%;
`;

const OutputSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;

  @media (max-width:768px) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }
`;

const ImageFramer = styled.div`
cursor: pointer;
position: relative;
 width: 20vw;
 height: 22vw;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 5px gray;
  
  @media (max-width:768px) 
  {
       min-width: 45vw;
       min-height: 40vh;
   }
   img{
    width: 90%;
     border-radius: 10px;
     box-shadow: 2px 2px 6px 2px gray;

     @media (max-width:768px) 
     {
               width: 70%;
      }

    }
`;
const ActionButtons = styled.div`
width: 95%;
height: 95%;
position: absolute;
padding:1rem;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 5px;
 opacity: 0%;
 transition: 0.15s ease-out;
  &:hover{
    background-color: lightgray;
    opacity: 80%;
    border-radius: 10px;
  }

 svg{
  cursor: pointer;
 }
 button{
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 0.3rem;
  border-radius: 3.5px;
 }
`;



const MockUpBox = styled.div`
   box-shadow: 1px 1px 1px 4px gray;
     z-index: 4;
     min-width: 33vw;
     min-height: 60vh;
     border-radius:10px;
      padding: 2%;
     background-color: white;
     z-index: 3;
     position: absolute;
     @media (max-width:768px) {
        min-width: 60vw;
        min-height: 35vh;
      }
     svg{ 
         float: right;
        transition: 0.1s ease-in;
         height: 4vh;
         width: 2vw;
       &:hover{
        background-color: red;
        border-radius:50%;
       }
     }
     h3{
       font-weight: 600;
      text-align: center;
     }
`;
const CardSection= styled.div`
min-height: 60vh;
  box-shadow: 5px 5px 10px 5px gray; 
  margin-top: 10px;
  border-radius: 10px;
 background-color: lightblue;
 padding: 1rem;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 10px;
 flex-wrap: wrap;
 @media (max-width:768px)
 {
  flex-direction: column;
 }
`;

const FullScreen = styled.div`
  position: absolute;
  bottom:4px;
  right: 0.8rem;

  
`;


const SVGlike = styled.svg`
 transition: 0.3s ease-in;

 &:hover{
   border-radius: 50%;
  background-color: lightblue;
    height: 3rem;
    width: 2rem;
 }
`;
const SVGdislike = styled.svg`
 transition: 0.3s ease-in;

&:hover{
  border-radius: 50%;
 background-color: red;
   height: 3rem;
   width: 2rem;
}

`;
const SVGdownload = styled.svg`
 transition: 0.3s ease-in;

&:hover{
  border-radius: 50%;
 background-color: lightgreen;
   height: 3rem;
   width: 2rem;
}

`;

